import React from 'react';
import styles from './UserGuide.module.css'
import PDFModal from "./modals/PDFModal";
import { useSelector } from "react-redux";
import {getUserGuideEntriesAPI} from "../../api/userGuideAPI";
import {getUserGuideMangementAPI} from '../../api/userGuideAPI';
import Loading from "../General/loading/loading";
import { modalClose, modalOpen } from "../../store/modalSlice";
import { useDispatch } from "react-redux";
import UserGuideEntryModal from './modals/userGuideEntryModal';

const MODALS = {
    NONE: null,
    ADD_USER_GUIDE_ENTRY: "USER_GUIDE_ENTRY",
    USER_GUIDE_ENTRY: "USER_GUIDE"
}
const UserGuide = () => {

    const docket = useSelector((state) => state.navigation.currentDocket);
    const jwt = useSelector((state) => state.user.jwt);

    const [selectedHelpArticle, setSelectedHelpArticle] = React.useState([-1, '']);
    const [guideData, setGuideData] = React.useState({})
    const [loading, setLoading] = React.useState(true)
    const [modalControl, setModalControl] = React.useState(MODALS.NONE);
    const [guideCategories, setGuideCategories] = React.useState([])
    const [enableUserGuideEntry, setEnableUserGuideEntry] = React.useState(false);

    

    const getDocketEntryType = () => {
            for (let category in guideData) {
                if (guideData.hasOwnProperty(category)) {
                    const entries = guideData[category]['data'];
                    for (let i = 0; i < entries.length; i++) {
                        if (entries[i][0] === selectedHelpArticle[0]) {
                            return entries[i][2];
                        }
                    }
                }
            }
          
    };
    

    getDocketEntryType();

    const dispatch = useDispatch();
    
    const openModal = (modalID) => {
        setModalControl(modalID)
        dispatch(modalOpen())
    }

    const closeAllModals = () => {
        setModalControl(MODALS.NONE)
        if (modalControl === MODALS.USER_GUIDE_ENTRY) {
            setSelectedHelpArticle([-1, ''])
        }
        dispatch(modalClose())
    }

    const openGuideEntry = (contentItem) => {
        setSelectedHelpArticle(contentItem); 
        openModal(MODALS.USER_GUIDE_ENTRY);
    }

    React.useEffect(() => {
        setLoading(true)
        getUserGuideEntriesAPI(jwt, docket).then(r => {
            setGuideCategories(Object.keys(r))
            setGuideData(r)
            setLoading(false)
        }).catch(e => {
            setGuideData({})
            setLoading(false)
        })
        getUserGuideMangementAPI(jwt, docket).then((r) => {
            setEnableUserGuideEntry(r.permission)
        })
    }, [jwt, docket])

    return (
        <div className={styles.userGuide}>
            <div className={styles.guideEntryButtonContainer}>
                <h3 className={styles.heading}>How can we help you?</h3>
                {enableUserGuideEntry && <button className={styles.guideEntryButton} onClick={() => { openModal(MODALS.ADD_USER_GUIDE_ENTRY) }}>Add User Guide Entry</button>}
            </div>
            <div className={styles.content}>
                {
                    loading && <Loading />
                }
                {
                    !loading && Object.keys(guideData).length === 0 &&
                    <h3 style={{ color: 'darkred' }} className={styles.heading}>No User Guide Content Found</h3>
                }
                {
                    !loading && Object.keys(guideData).map(category => (
                        <div key={category} className={styles.categoryWrapper}>
                            <div className={styles.categoryHeader}>
                                <img onContextMenu={() => {
                                    return false;
                                }} style={{ height: 30, width: 30 }}
                                    className={`${styles.icon} noSelect`}
                                    src={`/assets/imgs/user_guide/${guideData[category]['icon']}.svg`}
                                    alt={"Chevron Arrow"} />
                                {category}
                            </div>
                            <div className={styles.categoryContent}>
                                {
                                    guideData[category]['data'].map(contentItem => (
                                        <button key={contentItem[1]} onClick={() => { openGuideEntry(contentItem) }}
                                            className={styles.categoryArticle}>
                                            {contentItem[1]}
                                        </button>
                                    ))
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
            <PDFModal open={modalControl === MODALS.USER_GUIDE_ENTRY} closeModal={closeAllModals} modalInformation={selectedHelpArticle}
                enableManagement={enableUserGuideEntry} docketEntryType={getDocketEntryType()}  guideData={guideData} setGuideData={setGuideData}/>
            <UserGuideEntryModal open={modalControl === MODALS.ADD_USER_GUIDE_ENTRY} closeModal={closeAllModals} guideCategories={guideCategories} />
        </div>
    );
};

export default UserGuide;