import {useProductionURL} from "../helper/constants";

//Returns the comment text based on tracking number and attachment
export const getCommentTextAPI = async (jwt, docketID, trackingNumber, attachment) => {

    if (trackingNumber === undefined || trackingNumber === null || trackingNumber === '') {
        return 'No comment text found'
    }

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/comment/get_comment_text?` + new URLSearchParams({
        docket: docketID,
        tracking: trackingNumber,
        attachment: attachment
    }), {
        headers: {
            "auth": jwt
        }
    })

    const txt = await resp.text()

    return (txt.length < 1 ? "No comment text available" : txt)
}

//Returns the comment text based on tracking number and attachment
export const getTrackingNumberAPI = async (jwt, docketID, documentID) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/comment/get_tracking_number?` + new URLSearchParams({
        docket: docketID,
        documentID: documentID
    }), {
        headers: {
            "auth": jwt
        }
    })

    const txt = await resp.text()

    return (txt.length < 1 ? "" : txt)
}

//Returns the comment metadata based on tracking number
export const getCommentMetadata = async (jwt, docketID, trackingNumber) => {

    if (trackingNumber === 'none') {
        return {
            commenter: 'No Comment Selected',
            mcGroup: 3,
            status: 'No Comment Selected'
        }
    }

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/comment/get_metadata?` + new URLSearchParams({
        docket: docketID,
        tracking: trackingNumber
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

//Returns the comment topics based on tracking number
export const getCommentTopicsAPI = async (jwt, docketID, trackingNumber) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/comment/get_topics?` + new URLSearchParams({
        docket: docketID,
        tracking: trackingNumber
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

//Returns the comment summary for MCs based on topic & subtopic
export const getCommentSummary = async (jwt, docketID, trackingNumber, topic, subtopic) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/comment/get_summary?` + new URLSearchParams({
        docket: docketID,
        tracking: trackingNumber,
        topic: topic,
        subtopic: subtopic
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.text()
}

//Returns the comment other text (Out of Scope) based on tracking number
export const getCommentOtherText = async (jwt, docketID, trackingNumber, otherType) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/comment/get_other?` + new URLSearchParams({
        docket: docketID,
        tracking: trackingNumber,
        type: otherType
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.text()
}

//Returns the annotation for a topic/subtopic combination based on the user
export const getCommentAnnotation = async (jwt, docketID, trackingNumber, topic, subtopic) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/comment/get_annotation?` + new URLSearchParams({
        docket: docketID,
        tracking: trackingNumber,
        topic: topic,
        subtopic: subtopic
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

//Returns the annotation for a topic/subtopic combination based on the user
export const updateCommentAnnotation = async (jwt, docketID, trackingNumber, topic, subtopic, newText) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/comment/update_annotation?` + new URLSearchParams({
        docket: docketID,
        tracking: trackingNumber,
        topic: topic,
        subtopic: subtopic
    }), {
        headers: {
            "auth": jwt,
            "Content-Type": "application/json"
        },
        method: "PUT",
        body: JSON.stringify({
            "text": newText
        })
    })

    return await resp.json()
}

export const updateCommentViewed = async (jwt, docketID, trackingNumber, topic, subtopic, viewed) => {

    let mainSubtopic = ""
    let mainTopic = ""

    if (topic !== undefined && topic !== null) {
        mainTopic = topic
    }

    if (subtopic !== undefined && subtopic !== null) {
        mainSubtopic = subtopic
    }

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/comment/update_viewed`, {
        headers: {
            "auth": jwt,
            "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify({
            "viewed": viewed,
            "docket": docketID,
            "tracking": trackingNumber,
            "topic": mainTopic,
            "subtopic": mainSubtopic
        })
    })

    return await resp.json()
}

export const getCommentPDFAPI = async (fileurl) => {
    const resp = await fetch(fileurl)

    return resp;
}

export const getAdvancedFilters = async (jwt, docketID, topic, subtopic, viewedFilters) => {
    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/comment/advanced_filters?` + new URLSearchParams({
        docket: docketID,
        topic: topic,
        subtopic: subtopic,
        myview: viewedFilters.personal,
        teamview: viewedFilters.team
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const updateSentimentAPI = async (jwt, docket, tracking, sentiment, topic, subtopic) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/comment/update_sentiment?` + new URLSearchParams({
        docket: docket,
        tracking: tracking,
        sentiment: sentiment,
        topic: topic ?? "",
        subtopic: subtopic ?? ""
    }), {
        method: "PUT",
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const getCommentAttachmentsAPI = async (jwt, docket, documentID) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/comment/get_attachments?` + new URLSearchParams({
        docket: docket,
        documentID: documentID
    }), {
        method: "GET",
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const getCommentTimingInformation = async (jwt, docketID, trackingNumber) => {

    if (trackingNumber === 'none' || trackingNumber === '' || trackingNumber == null) {
        return {
            posted: undefined,
            triaged: undefined,
            completed: undefined
        }
    }

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/comment/get_timing_overview?` + new URLSearchParams({
        docket: docketID,
        tracking: trackingNumber
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}

export const getOriginalAttachmentLinkAPI = async (jwt, docketID, trackingNumber, attachment) => {

    const resp = await fetch(`http${useProductionURL ? "s" : ""}://${window.location.hostname}${useProductionURL ? "/api" : ":5000"}/comment/get_original_link?` + new URLSearchParams({
        docket: docketID,
        tracking: trackingNumber,
        attach: attachment
    }), {
        headers: {
            "auth": jwt
        }
    })

    return await resp.json()
}