import React from 'react';
import styles from "./CommentExplorer.module.css"
import { useDispatch, useSelector } from "react-redux";
import { getCommentsAPI } from "../../api/docketAPI";
import TextDisplay from "../General/TextDisplay/TextDisplay";
import InfoCard from "./InfoCard";
import Loading from "../General/loading/loading";
import {getAdvancedFilters, getCommentMetadata, getCommentTimingInformation} from "../../api/commentAPI";
import {FILTER_PERSIST_EXPIRY, MC_TITLES} from "../../helper/constants";
import flattenTopicsList from "../../helper/flattenTopicsList";
import {setDocToView} from "../../store/navSlice";
import ChevronIcon, {CHEVRON_DIRECTIONS} from "../General/icons/chevronIcon";
import CommentVisibilityModal from "./modals/commentVisibilityModal";
import InfoIcon from "../General/icons/infoIcon";
import MultiSelectFilter from "../Reports/reportLayouts/multiselectFilter/multiselectFilter";
import {orderUniqueColumnValues} from "../../helper/getUniqueColumnValues";
import {modalClose, modalOpen} from "../../store/modalSlice";
import CreateChangeRequestModal from "./modals/createChangeRequestModal";
import getUnixSeconds from "../../helper/getUnixSeconds";
import {setReportFilters} from "../../store/reportsSlice";
import parseReportFilters, {parseReportFiltersDict, parseReportFiltersString} from "../../helper/parseReportFilters";
import updateReportFilters from "../../helper/updateReportFilters";

const lookupTopicFilterByName = (filters, filterName) => {

    for (let filter of filters) {
        if (filter[1] === filterName) {
            return filter[0]
        }
    }

    return undefined
}

const calculateTimingValueToShow = (data, key) => {
    if (data == null) {
        return "Unknown"
    }

    if (!(key in data)) {
        return "Unknown"
    }

    if (data[key] == null) {
        return "Unknown"
    }

    return `${new Date(Math.round(data[key], 0) * 1000).toLocaleString(['en-US'], {
        timeZone: 'America/New_York',
        hour12: true,
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    })} EDT`
}

const filterComments = async (current, updateSelected, commentsList, allTopicsList, topicFilter, flFilter, textFilter, viewedFilters, mcGroupFilter, advancedFilterList, referredDocument) => {
    let temp = {}

    const lowerFilter = textFilter.toLowerCase()
    let skippedCurrent = false;
    let skippedReferred = null;

    const filter = lookupTopicFilterByName(allTopicsList, topicFilter)

    for (let key of Object.keys(commentsList)) {

        //Keep the select option at the top of the list.
        if (commentsList[key]["tracking"] === 'none') {
            temp[key] = commentsList[key]
            continue;
        }

        if (advancedFilterList.includes(commentsList[key]["tracking"])) {
            if (key === current[0]) {
                skippedCurrent = true
            }

            if (commentsList[key].documentID === referredDocument) {
                skippedReferred = commentsList[key]
            }
            continue
        }

        if (flFilter.length > 0) {
            if (!(flFilter.includes(commentsList[key]["flName"]))) {
                if (key === current[0]) {
                    skippedCurrent = true
                }
                if (commentsList[key].documentID === referredDocument) {
                    skippedReferred = commentsList[key]
                }
                continue
            }
        }

        const lowerTextDoc = commentsList[key]["documentID"].toLowerCase()
        if (!(lowerTextDoc.includes(lowerFilter))) {
            if (key === current[0]) {
                skippedCurrent = true
            }
            if (commentsList[key].documentID === referredDocument) {
                skippedReferred = commentsList[key]
            }
            continue
        }

        if (filter !== undefined && filter[0] !== '') {
            if (!(filter[0] in commentsList[key]["topics"])) {
                if (key === current[0]) {
                    skippedCurrent = true
                }
                if (commentsList[key].documentID === referredDocument) {
                    skippedReferred = commentsList[key]
                }
                continue
            } else {
                if (filter[1] !== undefined && filter[1] !== '') {
                    if (!(filter[1] in commentsList[key]["topics"][filter[0]]['children'])) {
                        if (key === current[0]) {
                            skippedCurrent = true
                        }
                        if (commentsList[key].documentID === referredDocument) {
                            skippedReferred = commentsList[key]
                        }
                        continue
                    }
                }
            }

        }

        if (!(mcGroupFilter.includes(commentsList[key]['mcGroup']))) {
            if (key === current[0]) {
                skippedCurrent = true
            }
            if (commentsList[key].documentID === referredDocument) {
                skippedReferred = commentsList[key]
            }
            continue
        }

        temp[key] = commentsList[key]
    }

    let updateList = null;

    //If selected comment isn't in the filter set
    if (skippedCurrent) {

        const filteredLength = Object.keys(temp).length

        //If the filter has zero or 1 comments, we need to show the current comment
        if (filteredLength === 0) {
            temp['none'] = commentsList['none']
            updateList = ['none', temp['none']["attachments"][0]]
        } else {
            //Else, skip to the first in the list
            let first = Object.keys(temp)[1]
            updateList = [first, temp[first]["attachments"][0]]
        }

    }

    if (skippedReferred != null && referredDocument !== "") {
        updateList = [skippedReferred.tracking, skippedReferred.attachments[0]]
        // temp[skippedReferred.tracking] = skippedReferred
    }

    const data = {
        updates: updateList,
        list: temp
    }

    return data
}

const getViewedLanguage = (viewedDict, username) => {
    if (!viewedDict.personal && !viewedDict.team) {
        return 'Showing All Comments'
    }

    if (viewedDict.personal && !viewedDict.team) {
        return `Showing Comments Unviewed by ${username}`
    }

    if (!viewedDict.personal && viewedDict.team) {
        return 'Showing Comments Unviewed by Others at CMS'
    }

    return 'Showing Completely Unviewed Comments'
}

const getFormLetterNames = (comments) => {

    let formLetters = []
    const flNames = []

    for (let comment of Object.keys(comments)) {

        if (comments[comment]['tracking'] === "none") {
            continue
        }

        const name = comments[comment]['flName']

        if (name === 'None') {
            continue
        }

        if (!(flNames.includes(name))) {
            flNames.push(name)

            formLetters.push({ value: name, label: name ?? "None" })
        }
    }

    formLetters = orderUniqueColumnValues(formLetters)

    return [{ value: 'None', label: "Non-Form Letters" }, { label: "Form Letters: ", text: true }, ...formLetters]
}

const CommentExplorer = () => {

    const [allComments, setAllComments] = React.useState({});


    const jwt = useSelector((state) => state.user.jwt);
    const displayName = useSelector((state) => state.user.displayName);
    const docket = useSelector((state) => state.navigation.currentDocket);
    const allTopics = useSelector((state) => state.dockets.docketCurrentTopics);
    const referredDocument = useSelector((state) => state.navigation.docToView);
    const storedFilters = useSelector((state) => state.reports.commentExplorer);
    const isReviewer = useSelector((state) => state.user.isReviewer)
    const isAdmin = useSelector((state) => state.user.isAdmin)
    const dispatch = useDispatch()

    const [selectedDocument, setSelectedDocument] = React.useState(["", 0])

    const [expandedFilters, setExpandedFilters] = React.useState(false)

    const mcGroupFilter = parseReportFilters('mcGroups', storedFilters)
    const flFilter = parseReportFilters('formLetters', storedFilters)
    const topicFilter = parseReportFiltersString('topicSelection', storedFilters)
    const nameFilter = parseReportFiltersString('documentID', storedFilters)
    const viewedFilter = parseReportFiltersDict('viewFilter', storedFilters)
    const [advancedFilterResp, setAdvancedFilterResp] = React.useState([])

    // eslint-disable-next-line no-unused-vars
    const [allCommentsLoading, setAllCommentsLoading] = React.useState(false)

    const [commenter, setCommenter] = React.useState(null)
    const [mcGroup, setMCGroup] = React.useState(3)
    const [status, setStatus] = React.useState("Unknown")
    const [timingValues, setTimingValues] = React.useState({})
    const inFL = !(status.includes("[QA]") || status.includes("[PR]")) && (status !== 'Completed')
    const [commentMetadataLoading, setCommentMetadataLoading] = React.useState(false)

    const [filteredComments, setFilteredComments] = React.useState([])

    const [commentVisibilityModalOpen, setCommentVisibilityModalOpen] = React.useState(false)
    const [changeRequestModalOpen, setChangeRequestModalOpen] = React.useState(false)

    const formLetterNames = React.useMemo(() => getFormLetterNames(allComments), [allComments])
    const allTopicsList = React.useMemo(() => flattenTopicsList(allTopics), [allTopics])

    const updateFilter = (key, value) => {
        updateReportFilters(dispatch, setReportFilters, "commentExplorer", storedFilters, key, value)
    }

    const changeDocument = (trackingNumber) => {
        dispatch(setDocToView(""));
        setSelectedDocument([trackingNumber, allComments[trackingNumber]["attachments"][0]])
    }

    const changeAttachment = (attachmentNumber) => {
        setSelectedDocument([selectedDocument[0], attachmentNumber])
    }

    const changeFLFilter = (newFilter) => {
        if (newFilter === undefined || newFilter.length < 1) {
            updateFilter("formLetters", ['None'])
        } else {
            updateFilter("formLetters", newFilter)
        }
    }

    const getFilterCount = () => {
        let count = 0;

        if (topicFilter !== '' && topicFilter !== ',') {
            count += 1
        }
        if (nameFilter !== '') {
            count += 1
        }
        if (mcGroupFilter.length < 3) {
            count += 1
        }
        if (flFilter.length !== 0 && flFilter.length !== formLetterNames.length && (flFilter.length !== 1 && flFilter.includes('None'))) {
            count += 1
        }

        if (count === 0) {
            return 'No Other Filters Active'
        } else if (count === 1) {
            return '1 Other Filter Active'
        }

        return `${count} Other filters active`
    }

    const toggleMCFilter = (mcID) => {
        if (mcGroupFilter.includes(mcID)) {
            const filtered = [...mcGroupFilter].filter(val => val !== mcID)

            if (filtered.length < 1) {
                updateFilter('mcGroups', [1, 2, 3])
            } else {
                updateFilter('mcGroups', [...filtered])
            }
        } else {
            updateFilter('mcGroups', [...mcGroupFilter, mcID])
        }


    }

    const closeModals = () => {
        dispatch(modalClose())

        setCommentVisibilityModalOpen(false)
        setChangeRequestModalOpen(false)
    }

    const openCommentVisibilityModal = () => {
        dispatch(modalOpen())

        setCommentVisibilityModalOpen(true)
        setChangeRequestModalOpen(false)
    }

    const openChangeRequestModal = () => {
        dispatch(modalOpen())

        setCommentVisibilityModalOpen(false)
        setChangeRequestModalOpen(true)
    }

    const updateSentiment = (tracking, topic, subtopic, sentiment) => {
        let temp = { ...allComments };

        if (subtopic === undefined || subtopic === null || subtopic === '') {
            temp[tracking].topics[topic].sentiment = sentiment
        } else {
            temp[tracking].topics[topic].children[subtopic] = sentiment
        }

        setAllComments(temp)
    }

    React.useEffect(() => {
        setAllCommentsLoading(true)
        filterComments(
            selectedDocument, setSelectedDocument,
            allComments, allTopicsList, topicFilter,
            flFilter, nameFilter, viewedFilter, mcGroupFilter,
            advancedFilterResp, referredDocument
        ).then(r => {
            if (r.list != null) {
                setFilteredComments(r.list)
            }

            if (r.updates != null) {
                setSelectedDocument(r.updates)
            }
            setAllCommentsLoading(false)
        }).catch(() => {
            setFilteredComments([])
        })
    }, [allComments, topicFilter, nameFilter, viewedFilter, mcGroupFilter, flFilter, allTopicsList, advancedFilterResp, referredDocument])

    React.useEffect(() => {

        const splitFilter = lookupTopicFilterByName(allTopicsList, topicFilter)
        getAdvancedFilters(jwt, docket, splitFilter === undefined ? "" : splitFilter[0], splitFilter === undefined ? "" : splitFilter[1], viewedFilter).then(r => {
            setAdvancedFilterResp(r)
        }).catch(() => {
            setAdvancedFilterResp([])
        })

    }, [jwt, docket, allTopicsList, topicFilter, viewedFilter])

    React.useEffect(() => {

        setAllCommentsLoading(true)
        getCommentsAPI(jwt, docket).then(resp => {

            let r = {
                'none': {
                    attachments: [0],
                    documentID: "-- Select --",
                    finalID: null,
                    hasOOS: false,
                    isFL: false,
                    topics: {},
                    tracking: 'none',
                    mcGroup: 3,
                    flName: 'None'
                }
            }

            for (let key of Object.keys(resp)) {
                r[key] = resp[key]
            }

            setAllComments(r)

            setAllCommentsLoading(false)
            if (Object.keys(r).length > 0) {
                let first = Object.keys(r)[0];
                if (referredDocument === undefined || referredDocument === "") {
                    setSelectedDocument([first, r[first]["attachments"][0]]);
                } else {

                    let useFirst = true
                    for (let trackingNumber of Object.keys(r)) {
                        if (r[trackingNumber].documentID === referredDocument || r[trackingNumber].finalID === referredDocument) {
                            setSelectedDocument([trackingNumber, r[trackingNumber]["attachments"][0]])
                            useFirst = false
                            break
                        }
                    }

                    if (useFirst) {
                        setSelectedDocument([first, r[first]["attachments"][0]])
                    }

                }
            }
        })
    }, [jwt, docket])

    React.useEffect(() => {
        return () => {
            dispatch(setDocToView(""));
        }
    }, [])

    //Splitting this out into a separate variable to limit reloading on
    //document-level items
    const [trackingNum, setTrackingNum] = React.useState("")
    React.useEffect(() => {

        if (selectedDocument === []) {
            return;
        }

        if (trackingNum !== selectedDocument[0]) {
            setTrackingNum(selectedDocument[0])
        }

    }, [trackingNum, selectedDocument])

    React.useEffect(() => {
        setCommentMetadataLoading(true)
        getCommentMetadata(jwt, docket, trackingNum).then(r => {
            setCommenter(r["commenter"])
            setMCGroup(r["mcGroup"])
            setStatus(r["status"])

            getCommentTimingInformation(jwt, docket, trackingNum).then(r => {
                if (r.success) {
                    setTimingValues(r.data)
                }
                setCommentMetadataLoading(false)
            }).catch(e => {
                setTimingValues({})
                setCommentMetadataLoading(false)
            })
        })
    }, [jwt, trackingNum])

    if (
        storedFilters === undefined || (storedFilters.saved + FILTER_PERSIST_EXPIRY < getUnixSeconds())
        || viewedFilter == null || Object.keys(viewedFilter).length < 1
    ) {
        dispatch(setReportFilters({
            reportType: "commentExplorer", data: {
                'mcGroups': [1, 2, 3],
                'formLetters': ['None'],
                'viewFilter': {
                    personal: false, team: false // These indicate whether they should be EXCLUDED
                }
            }
        }))
    }

    return (
        <div className={styles.parent}>
            <div className={styles.documentSearch}>
                <input placeholder={"Search Document ID"} aria-label={"Text Search for Document ID"} type={"text"}
                       onChange={(e) => updateFilter('documentID', e.currentTarget.value)} value={nameFilter}/>
            </div>

            <div className={`${styles.filters} ${expandedFilters ? styles.filtersExpanded : ''}`}>
                <div className={styles.mainFilters}>
                    <h2>Topic: </h2>
                    <select aria-label={"Topic Filter Select"} value={topicFilter}
                            onChange={(event) => updateFilter('topicSelection', event.currentTarget.value)}>
                        <option value={["", ""]}>-- Select --</option>
                        {
                            allTopicsList.map(element => (
                                <option key={element[1]} value={element[1]}>{element[1]}</option>
                            ))
                        }
                    </select>

                    <div className={styles.vertDivider}/>
                    <p style={{cursor: "pointer", width: "30%", flex: "3 0 auto"}}
                       onClick={() => setExpandedFilters(!expandedFilters)}>{getViewedLanguage(viewedFilter, displayName)}, {getFilterCount()}</p>

                    <button
                        style={{ backgroundColor: "lightblue", borderRadius: "50%", boxSizing: "border-box", padding: 4 }}
                        className={styles.expandedToggle} onClick={() => setExpandedFilters(!expandedFilters)}>
                        <ChevronIcon size={24}
                            direction={expandedFilters ? CHEVRON_DIRECTIONS.UP : CHEVRON_DIRECTIONS.DOWN} />
                    </button>
                </div>
                {
                    expandedFilters &&
                    <React.Fragment>
                        <div className={styles.horizontalDivider} />
                        <div className={styles.filtersLine}>
                            <div className={styles.filterGroup}>
                                <h2>Manage Comment Visibility <button onClick={openCommentVisibilityModal}
                                    className={styles.iconButton}><InfoIcon
                                        size={20} /></button>:
                                </h2>
                                <div className={styles.filterLineItem}>
                                    <input aria-label={"Exclude My Viewed Comments"} type={"checkbox"}
                                           checked={viewedFilter.personal}
                                           onChange={() => updateFilter('viewFilter', {
                                               ...viewedFilter,
                                               personal: !viewedFilter.personal
                                           })} />
                                    <p>Exclude My Viewed Comments</p>
                                </div>
                                <div className={styles.filterLineItem}>
                                    <input aria-label={"Exclude Others' Viewed Comments"} type={"checkbox"}
                                           checked={viewedFilter.team}
                                           onChange={() => updateFilter('viewFilter', {
                                               ...viewedFilter,
                                               team: !viewedFilter.team
                                           })} />
                                    <p>Exclude Others' Viewed Comments</p>
                                </div>
                            </div>
                            <div className={styles.filterGroup}>
                                <h2>Major Commenter Group: </h2>
                                <div className={styles.filterLineItem}>
                                    <input onChange={() => toggleMCFilter(1)} checked={mcGroupFilter.includes(1)}
                                        aria-label={"Priority MC"} type={"checkbox"} />
                                    <p>Priority MC</p>
                                </div>
                                <div className={styles.filterLineItem}>
                                    <input onChange={() => toggleMCFilter(2)} checked={mcGroupFilter.includes(2)}
                                        aria-label={"Other MC"} type={"checkbox"} />
                                    <p>Other MC</p>
                                </div>
                                <div className={styles.filterLineItem}>
                                    <input onChange={() => toggleMCFilter(3)} checked={mcGroupFilter.includes(3)}
                                        aria-label={"Non-MC"} type={"checkbox"} />
                                    <p>Non-MC</p>
                                </div>
                            </div>
                            {/*<div className={styles.filterGroup}>
                                <h2>Another Filter Here: </h2>
                                <div className={styles.filterLineItem}>
                                    <input aria-label={"Temporary Item"} type={"checkbox"} disabled={true} />
                                    <p>Option 1</p>
                                </div>
                                <div className={styles.filterLineItem}>
                                    <input aria-label={"Temporary Item"} type={"checkbox"} disabled={true} />
                                    <p>Option 2</p>
                                </div>
                                <div className={styles.filterLineItem}>
                                    <input aria-label={"Temporary Item"} type={"checkbox"} disabled={true} />
                                    <p>Option 3</p>
                                </div>
                            </div>*/}

                        </div>
                        <div className={styles.filtersLine}>
                            <div className={styles.filterGroup}>
                                <MultiSelectFilter title={"Comment Type"} width={"30vw"} selected={flFilter}
                                    setSelected={changeFLFilter} options={formLetterNames} />
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>

            <div className={styles.documentSelect}>
                {
                    <select className={styles.selectInput} size={4} aria-label={"Attachment Select"}
                        value={selectedDocument[0]}
                        onChange={(event) => changeDocument(event.currentTarget.value)}>
                        {
                            Object.keys(filteredComments).map(element => (
                                <option key={element}
                                    value={element}>{filteredComments[element]["documentID"]}</option>
                            ))
                        }
                    </select>
                }
            </div>

            <div className={styles.attachmentSelect}>
                <select className={styles.selectInput} size={4} aria-label={"Attachment Select"}
                    value={selectedDocument[1]} onChange={(event) => changeAttachment(event.currentTarget.value)}>
                    {
                        ((allComments[selectedDocument[0]] ?? { "attachments": [] })["attachments"].map(element => {
                            if (element === 0) {
                                return <option key={element} value={element}>General Comment</option>
                            } else {
                                return <option key={element} value={element}>Attachment {element}</option>
                            }
                        }
                        )
                        )
                    }
                </select>
            </div>


            <div className={`${styles.textArea} ${expandedFilters ? styles.textAreaExpanded : ''}`}>
                <TextDisplay documentID={(allComments[selectedDocument[0]] ?? { "documentID": "" })["documentID"]}
                    attachment={selectedDocument[1]} trackingNum={selectedDocument[0]} />
            </div>

            <div className={`${styles.commentInfo} ${expandedFilters ? styles.commentInfoExpanded : ''}`}>
                <InfoCard outOfScope={(allComments[selectedDocument[0]] ?? { hasOOS: false }).hasOOS} mcGroup={mcGroup}
                          selectedTrackingNumber={trackingNum} inFL={inFL} updateSentimentFunc={updateSentiment}
                          commentTopics={(allComments[selectedDocument[0]] ?? { topics: {} }).topics}
                          isFLParent={(allComments[selectedDocument[0]] ?? { isFLParent: false }).isFLParent}
                          flParentID={(allComments[selectedDocument[0]] ?? {flParent: 'Unknown'}).flParent}
                          mainTopicFilterValues={topicFilter}
                />
            </div>


            <div className={styles.metadata}>
                {
                    commentMetadataLoading ?
                        <Loading /> :
                        (
                            trackingNum == null || trackingNum === 'none' ?
                                <>
                                    <p className={styles.metaText} style={{textAlign: "center"}}><span
                                        className={styles.metaLabel}>No Comment Selected</span>
                                    </p>
                                </> :
                                <>
                                    {
                                        Object.keys(timingValues).length > 0 &&
                                        <>
                                            <p className={styles.metaText}><span
                                                className={styles.metaLabel}>Posted:</span> {calculateTimingValueToShow(timingValues, 'posted')}
                                            </p>
                                            <p className={styles.metaText}><span
                                                className={styles.metaLabel}>Loaded:</span> {calculateTimingValueToShow(timingValues, 'triaged')}
                                            </p>
                                            <p className={styles.metaText}><span
                                                className={styles.metaLabel}>Completed:</span> {calculateTimingValueToShow(timingValues, 'completed')}
                                            </p>
                                            <div className={styles.metadataDivider}/>
                                        </>
                                    }
                                    <p className={styles.metaText}><span
                                        className={styles.metaLabel}>Document ID: </span>{trackingNum == null || trackingNum === 'none' ? "No Comment Selected" : (
                                        allComments[selectedDocument[0]] === undefined ? "" :
                                            (allComments[selectedDocument[0]]).documentID
                                    )}
                                    </p>
                                    <p className={styles.metaText}><span
                                        className={styles.metaLabel}>Commenter: </span>{commenter == null ? "Unknown" : commenter}
                                    </p>

                                    <p className={styles.metaText}><span
                                        className={styles.metaLabel}>MC? </span> {MC_TITLES[mcGroup]}</p>
                                    <p className={styles.metaText}><span
                                        className={styles.metaLabel}>Status: </span> {status}
                                    </p>
                                    <div className={styles.metadataDivider}/>
                                    {
                                        trackingNum == null || trackingNum === 'none' ?
                                            null :
                                            <a target="_blank" rel="noreferrer"
                                               href={`https://www.regulations.gov/comment/${(allComments[trackingNum] ?? {documentID: ""})["documentID"]}`}>Link
                                                to Regulations.gov</a>
                                    }
                                </>
                        )
                }
            </div>

            <div className={styles.changeRequestWrapper}>
                {
                    (isReviewer && !isAdmin) ?
                        <p className={styles.metaText}
                           style={{
                               textAlign: "center",
                               height: '100%',
                               display: 'flex',
                               alignItems: "center",
                               justifyContent: "center"
                           }}>
                            <span
                                className={styles.metaLabel}>Change Requests disabled for reviewers</span>
                        </p> :
                        <button disabled={trackingNum == null || trackingNum === 'none'}
                                onClick={openChangeRequestModal}>
                            Create Change Request
                        </button>
                }
            </div>

            <CommentVisibilityModal closeModal={closeModals} open={commentVisibilityModalOpen}
                                    setFilter={(val) => updateFilter('viewFilter', val)}/>
            <CreateChangeRequestModal
                selectedDocument={
                    allComments[selectedDocument[0]] === undefined ? "" :
                        (allComments[selectedDocument[0]]).documentID}
                trackingNum={trackingNum}
                closeModal={closeModals} open={changeRequestModalOpen}/>
        </div>
    );
};

export default CommentExplorer;