import React from 'react';
import styles from './ManageExcerptModal.module.css'
import {createPortal} from "react-dom";
import useEscape from "../../../../helper/keypressListeners/escape";
import TopicCard from "../../topicCard/topicCard";
import cleanUpHTMLTags from "../../../../helper/cleanUpHTMLTags";
import ExcerptTopicReference from "./ExcerptTopicReference/ExcerptTopicReference";

const ManageExcerptModal = ({isOpen, closeModal, data, onMouseOver, addFunc, deleteFunc}) => {

    const [selectedExcerpt, setSelectedExcerpt] = React.useState([-1, 0]) // Attachment, index
    const [loading, setLoading] = React.useState(false);
    const [topicFilter, setTopicFilter] = React.useState("");

    const [topicReferenceInformation, setTopicReferenceInformation] = React.useState({
        isOpen: false,
        topic: null,
        subtopic: null
    })

    const updateTopicReferenceInformation = (topic, subtopic) => {
        setTopicReferenceInformation({isOpen: true, topic: topic, subtopic: subtopic})
    }

    const closeTopicReferenceInformation = () => {
        setTopicReferenceInformation({isOpen: false, topic: null, subtopic: null})
    }

    const clearAndCloseModal = () => {
        closeTopicReferenceInformation()
        closeModal()
    }

    // Calculates the list of excerpts that are currently selected in the topic filter
    const calculateExcerptsList = () => {
        const excerptText = {}
        let topicSubtopicList = topicFilter.split("||")
        const topic = topicSubtopicList[0]
        const subtopic = topicSubtopicList[1]

        if (topic === undefined || topic === "" || !isOpen) {
            return data
        }

        if (topic !== undefined && topic !== "" && data !== null && data !== undefined) {
            for (let attach of Object.keys(data)) {
                for (let excerpt of data[attach]) {
                    if (Object.keys(excerpt.topics).includes(topic)) {
                        if (subtopic === undefined || subtopic === "") {
                            if (!(Object.keys(excerptText).includes(attach))) {
                                excerptText[attach] = []
                            }
                            excerptText[attach].push(excerpt)
                        } else {
                            for (let subtopicIteration of excerpt.topics[topic].subtopics) {
                                if (subtopicIteration[0] === subtopic) {
                                    if (!(Object.keys(excerptText).includes(attach))) {
                                        excerptText[attach] = []
                                    }
                                    excerptText[attach].push(excerpt)
                                }
                            }
                        }
                    }
                }
            }
        }

        return excerptText
    }

    const excerptList = calculateExcerptsList()

    // Calculates all the topics present in the excerpts themselves
    const calculateExcerptTopics = () => {
        const excerptTopicList = []

        if (!isOpen) {
            return []
        }

        if (data !== null && data !== undefined) {
            for (let attach of Object.keys(data)) {
                for (let excerpt of data[attach]) {

                    for (let excerptTopic of Object.keys(excerpt.topics)) {
                        if (!(excerptTopicList.includes(`${excerptTopic}||`))) {
                            excerptTopicList.push(`${excerptTopic}||`)
                        }

                        for (let excerptSubtopic of excerpt.topics[excerptTopic].subtopics) {
                            if (!(excerptTopicList.includes(`${excerptTopic}||${excerptSubtopic[0]}`))) {
                                excerptTopicList.push(`${excerptTopic}||${excerptSubtopic[0]}`)
                            }
                        }
                    }
                }
            }
        }

        excerptTopicList.sort()
        return excerptTopicList
    }

    const allExcerptedTopics = calculateExcerptTopics()

    const updateSelection = (attach, index) => {
        setSelectedExcerpt([attach, index])
    }

    const updateSelectedTopics = (topic, subtopic, sentiment) => {
        const attach = selectedExcerpt[0]
        const index = selectedExcerpt[1]

        if (attach === -1) {
            return;
        }
        const excerptData = excerptList[attach][index]

        // Check to see if topic/subtopic already exists
        if (Object.keys(excerptData.topics).includes(topic)) {
            if (subtopic === '') {
                deleteFunc([excerptData.topics[topic].id])
                return
            } else {
                for (let existingSubtopic of excerptData.topics[topic].subtopics) {
                    if (subtopic === existingSubtopic[0]) {
                        setLoading(true)
                        deleteFunc([existingSubtopic[1]])
                        return;
                    }
                }
            }
        }

        //Adding a topic if it's made it this far
        let indexes = null;
        if (attach > 0) {
            indexes = [excerptData.startPage, excerptData.startIndex, excerptData.endPage, excerptData.endIndex]
        }

        setLoading(true)
        addFunc(excerptData.text, indexes, [[topic, subtopic]], parseInt(attach))
    }

    useEscape(clearAndCloseModal)

    React.useEffect(() => {
        setSelectedExcerpt([-1, 0])
    }, [isOpen])

    React.useEffect(() => {
        setLoading(false)
    }, [data, isOpen])

    React.useEffect(() => {
        closeTopicReferenceInformation()
    }, [selectedExcerpt])

    if (!isOpen) {
        return null;
    }

    // Make sure there's excerpt data
    if (data === null || data === undefined) {
        return null;
    }

    if (selectedExcerpt === undefined) {
        setSelectedExcerpt([-1, 0])
    }

    // Covers the edge case when removing all the topics from the bottom/last excerpt of an attachment
    if (selectedExcerpt[0] !== -1 &&
        (excerptList[selectedExcerpt[0]] === undefined || excerptList[selectedExcerpt[0]].length <= selectedExcerpt[1])) {
        setSelectedExcerpt([-1, 0])
        return null;
    }

    let selectedTopics = {}

    if (selectedExcerpt[0] !== -1) {
        for (let topic of Object.keys(excerptList[selectedExcerpt[0]][selectedExcerpt[1]].topics)) {
            selectedTopics[topic] = {children: {}, sentiment: -99}
            for (let subtopic of excerptList[selectedExcerpt[0]][selectedExcerpt[1]].topics[topic].subtopics) {
                selectedTopics[topic].children[subtopic[0]] = -99
            }
        }
    }

    return createPortal(
        <div autoFocus={false} role={'dialog'} style={{display: isOpen ? 'flex' : 'none'}} aria-modal={true}
             aria-hidden={!isOpen} onClick={clearAndCloseModal} onMouseOver={onMouseOver}
             className={styles.wrapper} aria-describedby={'modalDescription'}>
            <div className={styles.largeContent} onClick={(event) => {
                event.stopPropagation();
            }}>
                <div className={styles.modalDescription} id={'modalDescription'}>
                    This is a dialog window which overlays the main content of the page.
                    The modal begins with a heading called &quot;Manage Excerpts&quot;.
                    Pressing the Close Modal button at the top will close the modal and bring you back to where you were
                    on the page.
                </div>
                <div style={{width: '100%'}}>
                    <h1 className={styles.h1}>Manage Excerpts</h1>
                    <button className={styles.closeX} title={"Close Modal"} aria-label={"Close Modal"} autoFocus={true}
                            onClick={clearAndCloseModal}>X
                    </button>
                </div>

                <div className={styles.inside_wrapper}>
                    <div className={styles.excerpt_list}>
                        <select className={styles.dropdown} aria-label={"Summary Topic Select"} value={topicFilter}
                                onChange={(event) => setTopicFilter(event.currentTarget.value)}>
                            <option value={""}>-- Select --</option>
                            {
                                allExcerptedTopics.map(topicStr => (
                                    <option key={topicStr} value={topicStr}>{topicStr.replace("||", " || ")}</option>
                                ))
                            }
                        </select>

                        <div className={styles.excerpt_selection}>
                            {
                                Object.keys(excerptList).map(attachment => (
                                    <React.Fragment key={attachment}>
                                        <p>{attachment === 0 ? "General Comment" : `Attachment ${attachment}`}</p>
                                        {
                                            excerptList[attachment].map((excerpt, index) => (
                                                <div key={index} onClick={() => updateSelection(attachment, index)}
                                                     className={`${styles.excerpt_select_div}
                                                 ${(attachment === selectedExcerpt[0] && index === selectedExcerpt[1]) ? styles.excerpt_select_highlight : ''}`}>
                                                    <p>{excerptList[attachment][index].text.substring(0, 150)}</p>
                                                </div>
                                            ))
                                        }
                                    </React.Fragment>
                                ))
                            }
                            {
                                Object.keys(excerptList).length < 1 &&
                                <p>No Excerpts Found...</p>
                            }
                        </div>
                    </div>
                    <div className={styles.divider}/>
                    {
                        selectedExcerpt[0] === -1 &&
                        <div className={styles.noSelection}>
                            <h2>Select an Excerpt</h2>
                        </div>
                    }
                    {
                        selectedExcerpt[0] !== -1 &&
                        <React.Fragment>
                            <div className={styles.raw_text_display}>
                                {
                                    cleanUpHTMLTags(excerptList[selectedExcerpt[0]][selectedExcerpt[1]].text).split("\n").map(
                                        (textChunk, idx) => (
                                            <p key={idx}>{textChunk}</p>
                                        )
                                    )
                                }
                            </div>
                            <div className={styles.topicsSection}>
                                <div className={styles.topicsWrapper}
                                     style={{display: "flex", flexDirection: "column"}}>
                                    <TopicCard selectedTopics={selectedTopics} isPaused={false}
                                               useReferenceHelp={'custom'}
                                               customReferenceFunction={updateTopicReferenceInformation}
                                               useSentiment={false} disabled={loading} riderShow={'none'}
                                               toggleSelectedTopicFunc={updateSelectedTopics}/>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                    <div style={{
                        width: topicReferenceInformation.isOpen ? 500 : 0,
                        border: topicReferenceInformation.isOpen ? '1px solid grey' : 'none'
                    }}
                         className={styles.referenceSection}>
                        <ExcerptTopicReference open={topicReferenceInformation.isOpen}
                                               closeFunction={closeTopicReferenceInformation}
                                               topic={topicReferenceInformation.topic}
                                               subtopic={topicReferenceInformation.subtopic}
                        />
                    </div>
                </div>
            </div>
        </div>,
        document.body
    );
};


export default ManageExcerptModal;