import React from 'react';
import styles from "./InfoCard.module.css"
import calculateSentimentTag from "../../helper/calculateSentimentTag";
import {useSelector} from "react-redux";
import {
    getCommentAnnotation,
    getCommentOtherText,
    getCommentSummary,
    updateCommentAnnotation, updateCommentViewed, updateSentimentAPI
} from "../../api/commentAPI";
import Loading from "../General/loading/loading";
import InfoIcon from "../General/icons/infoIcon";
import getNonRiderTopics from '../../helper/getNonRiderTopics';
const tabs = ["Topics", "Summaries", "Out Of Scope", "Annotation"]
const InfoCard = ({
                      commentTopics,
                      updateSentimentFunc,
                      selectedTrackingNumber,
                      mcGroup,
                      outOfScope,
                      inFL,
                      isFLParent,
                      flParentID,
                      mainTopicFilterValues
                  }) => {

    const allDocketTopics = useSelector((state) => state.dockets.docketCurrentTopics)
    const jwt = useSelector((state) => state.user.jwt)
    const docket = useSelector((state) => state.navigation.currentDocket);
    const isReviewer = useSelector((state) => state.user.isReviewer)
    const isAdmin = useSelector((state) => state.user.isAdmin)
    const [selectedTab, setSelectedTab] = React.useState(tabs[0]);
    const nonRiderTopics = getNonRiderTopics(jwt, docket, commentTopics, allDocketTopics);

    const chooseTab = () => {
        if (selectedTab === undefined || selectedTab === null) {
            return null
        }

        if (selectedTab === "Topics") {
            return <TopicsTab commentTopics={commentTopics} mcGroup={mcGroup} inFL={inFL}/>
        }

        if (selectedTab === "Summaries") {
            return <SummaryTab tracking={selectedTrackingNumber} topics={nonRiderTopics}
                               mainTopicFilter={mainTopicFilterValues}/>
        }

        if (selectedTab === "Out Of Scope") {
            return <OtherTab tracking={selectedTrackingNumber}/>
        }

        if (selectedTab === "Annotation") {
            return <AnnotationTab
                updateSentimentFunc={updateSentimentFunc} tracking={selectedTrackingNumber} topics={commentTopics}
                inFL={inFL} isFLParent={isFLParent} flParentID={flParentID} mainTopicFilter={mainTopicFilterValues}
            />
        }
    }

    React.useEffect(() => {
        if (mcGroup === 3 && selectedTab === tabs[1]) {
            setSelectedTab(tabs[0])
        }

        if (!outOfScope && selectedTab === tabs[2]) {
            setSelectedTab(tabs[0])
        }
    }, [mcGroup, outOfScope, tabs, selectedTab])

    const getTabs = () => {
        if (isReviewer && !isAdmin) {
            return tabs.filter(val => val !== "Annotation")
        }

        return tabs
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.tabs}>
                {getTabs().map((element) => (
                    <Tab
                        disabled={((mcGroup === 3 || Object.keys(nonRiderTopics).length === 0) && (element === tabs[1])) || (!outOfScope && element === tabs[2])}
                        key={element} selectedItem={selectedTab}
                        text={element} onClick={() => setSelectedTab(element)}/>
                ))}
            </div>
            <div className={styles.content}>
                {chooseTab()}
            </div>

        </div>
    );
};

const Tab = ({selectedItem, disabled, text, onClick}) => {
    return <button onClick={onClick} disabled={disabled}
                   className={`${styles.tab} ${selectedItem === text && styles.selectedTab}`}>
        {text}</button>
}

const TopicsTab = ({commentTopics, mcGroup, inFL}) => {

    return (
        <>
            {
                Object.keys(commentTopics).length < 1 &&
                <p className={styles.topicName}>No Topics Identified</p>
            }
            {
                Object.keys(commentTopics).map(key => (
                    <React.Fragment key={key}>
                        <p className={styles.topicName}>
                            {key} {(commentTopics[key]["sentiment"] !== -99) ? `[${calculateSentimentTag(commentTopics[key]["sentiment"])}]` : ""}
                        </p>
                        {
                            Object.keys(commentTopics[key]['children']).map(subtopic => (
                                <p key={subtopic} className={styles.subtopicName}>
                                    -- {subtopic} {(commentTopics[key]["children"][subtopic] !== -99) ? `[${calculateSentimentTag(commentTopics[key]["children"][subtopic])}]` : ""}
                                </p>
                            ))
                        }
                    </React.Fragment>
                ))
            }
        </>
    )
}

const SummaryTab = ({tracking, topics, mainTopicFilter}) => {

    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);

    const [topicFilter, setTopicFilter] = React.useState("")

    const [summaryText, setSummaryText] = React.useState("")
    const [summaryLoading, setSummaryLoading] = React.useState(false)

    React.useEffect(() => {
        if (topicFilter === "") {
            setSummaryText("")
        } else {
            let topicSubtopicSplit = topicFilter.split("||")
            setSummaryLoading(true)
            getCommentSummary(jwt, docket, tracking, topicSubtopicSplit[0], topicSubtopicSplit[1]).then(r => {
                setSummaryLoading(false)
                setSummaryText(r)
            })
        }
    }, [topicFilter])

    React.useEffect(() => {
        setSummaryLoading(false)
        setSummaryText("")

        if (mainTopicFilter === "" || mainTopicFilter === "," || mainTopicFilter === " , ") {
            setTopicFilter("")
        } else {
            if (mainTopicFilter.toString().includes("||")) {
                setTopicFilter(mainTopicFilter.toString().split(" || ").join("||"))
            } else {
                setTopicFilter(`${mainTopicFilter}||`)
            }
        }
    }, [tracking, mainTopicFilter])

    return (
        <>
            <select className={styles.topicBasedSelect} aria-label={"Summary Topic Select"} value={topicFilter}
                    onChange={(event) => setTopicFilter(event.currentTarget.value)}>
                <option value={""}>-- Select --</option>
                {
                    Object.keys(topics).map(topic => (
                        <React.Fragment key={`${topic}||`}>
                            <option value={`${topic}||`}>{topic}</option>
                            {
                                Object.keys(topics[topic]['children']).map(subtopic => (
                                    <option key={`${topic}||${subtopic}`}
                                            value={`${topic}||${subtopic}`}>{topic} || {subtopic}</option>
                                ))
                            }
                        </React.Fragment>
                    ))
                }
            </select>
            {
                summaryLoading ? <Loading/> :
                    <p className={styles.staticUserText}>
                        {summaryText}
                    </p>
            }
        </>
    )
}

const OtherTab = ({tracking}) => {

    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);

    const [otherText, setOtherText] = React.useState("")
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        setLoading(true)
        getCommentOtherText(jwt, docket, tracking, 2).then(r => {
            setLoading(false)
            setOtherText(r)
        })
    }, [tracking])

    return (
        <>
            {
                loading ? <Loading/> :
                    <p className={styles.staticUserText}>
                        {otherText}
                    </p>
            }
        </>
    )
}

const AnnotationTab = ({tracking, topics, updateSentimentFunc, inFL, isFLParent, flParentID, mainTopicFilter}) => {
    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);
    const autoMarkedSelected = useSelector((state) => state.prefs.viewAnnotation)

    const [topicFilter, setTopicFilter] = React.useState("")

    const [annotationText, setAnnotationText] = React.useState("")
    const [annotationUser, setAnnotationUser] = React.useState("")
    const [annotationDate, setAnnotationDate] = React.useState("")
    const [annotationAuthor, setAnnotationAuthor] = React.useState("")

    const [loading, setLoading] = React.useState(false)
    const [newAnnotationLoaded, setNewAnnotationLoaded] = React.useState(false)
    const [isSaved, setIsSaved] = React.useState(true)

    const [isTopicViewed, setIsTopicViewed] = React.useState(false)

    const updateText = (e) => {
        if (autoMarkedSelected && !isTopicViewed) {
            markTopicAsViewed();
        }
        setNewAnnotationLoaded(false)
        setAnnotationText(e.currentTarget.value)
    }

    const getViewedLabel = () => {
        let topicSubtopicSplit = topicFilter.split("||")

        if (topicSubtopicSplit[0] === undefined || topicSubtopicSplit[0] === '') {
            return 'Mark Comment as Viewed'
        }

        if (topicSubtopicSplit[1] === undefined || topicSubtopicSplit[1] === '') {
            return 'Mark Topic as Viewed'
        }

        return 'Mark Subtopic as Viewed'
    }

    const markTopicAsViewed = () => {
        const isViewed = !isTopicViewed
        let topicSubtopicSplit = topicFilter.split("||")

        updateCommentViewed(jwt, docket, tracking, topicSubtopicSplit[0], topicSubtopicSplit[1], isViewed).then(r => {
            if (r.success) {
                setIsTopicViewed(isViewed)
            }
        })
    }

    const updateSentiment = (newSentiment) => {
        try {
            let topicSubtopicSplit = topicFilter.split("||")

            updateSentimentAPI(jwt, docket, tracking, newSentiment, topicSubtopicSplit[0], topicSubtopicSplit[1]).then(r => {
                if (r.success) {
                    updateSentimentFunc(tracking, topicSubtopicSplit[0], topicSubtopicSplit[1], newSentiment)
                }
            })
        } catch (e) {
            // Do nothing for now. TODO: error logging
        }
    }

    React.useEffect(() => {
        if (topicFilter === "") {
            getCommentAnnotation(jwt, docket, tracking, "", "").then(r => {
                setLoading(false)
                setAnnotationText("")
                setAnnotationUser("")
                setAnnotationDate("")
                setAnnotationAuthor("")
                setIsTopicViewed(r['viewed'] ?? false)
                setNewAnnotationLoaded(true)
                setIsSaved(true)
            })
        } else {
            let topicSubtopicSplit = topicFilter.split("||")
            setLoading(true)

            getCommentAnnotation(jwt, docket, tracking, topicSubtopicSplit[0], topicSubtopicSplit[1]).then(r => {
                setLoading(false)
                setAnnotationText(r['text'])
                setAnnotationUser(r['user'])
                setAnnotationDate(r['date'])
                setAnnotationAuthor(r['authors'])
                setIsTopicViewed(r['viewed'] ?? false)
                setNewAnnotationLoaded(true)
                setIsSaved(true)
            })

        }
    }, [topicFilter, tracking])

    React.useEffect(() => {
        setLoading(true)
        setAnnotationText("")

        if (mainTopicFilter === "" || mainTopicFilter === "," || mainTopicFilter === " , ") {
            setTopicFilter("")
        } else {
            if (mainTopicFilter.toString().includes("||")) {
                setTopicFilter(mainTopicFilter.toString().split(" || ").join("||"))
            } else {
                setTopicFilter(`${mainTopicFilter}||`)
            }
        }
    }, [tracking, mainTopicFilter])

    React.useEffect(() => {

        if (!newAnnotationLoaded) {
            setIsSaved(false)
        }

        const timeoutID = setTimeout(() => {
            if (!newAnnotationLoaded) {
                let topicSubtopicSplit = topicFilter.split("||")
                updateCommentAnnotation(jwt, docket, tracking, topicSubtopicSplit[0], topicSubtopicSplit[1], annotationText).then(r => {
                    setIsSaved(true)
                })
            }
        }, 750)

        return () => {
            clearTimeout(timeoutID)
        }
    }, [annotationText, newAnnotationLoaded])

    const isTopicSelected = (topicFilter !== "")


    const filterSplit = topicFilter.split("||")
    let sentiment = -99;
    try {
        if (filterSplit.length < 2) {
            sentiment = -99;
        } else if (filterSplit[1] === '') {
            sentiment = topics[filterSplit[0]].sentiment
        } else {
            sentiment = topics[filterSplit[0]]['children'][filterSplit[1]]
        }
    } catch (e) {
        // Do nothing
    }

    return (
        <div className = {styles.contentWrapper}>
            <select className={styles.topicBasedSelect} aria-label={"Annotation Topic Select"} value={topicFilter}
                    onChange={(event) => setTopicFilter(event.currentTarget.value)}>
                <option value={""}>-- Select --</option>
                {
                    Object.keys(topics).map(topic => (
                        <React.Fragment key={`${topic}||`}>
                            <option value={`${topic}||`}>{topic}</option>
                            {
                                Object.keys(topics[topic]['children']).map(subtopic => (
                                    <option key={`${topic}||${subtopic}`}
                                            value={`${topic}||${subtopic}`}>{topic} || {subtopic}</option>
                                ))
                            }
                        </React.Fragment>
                    ))
                }
            </select>

            {
                !isTopicSelected &&
                <p className={styles.lastEdit}>Select a topic above to begin an annotation or select a sentiment!</p>
            }

            {
                isTopicSelected &&
                <div className={styles.horizontalDivider}/>
            }

            {
                !isTopicSelected ? null :
                    <>
                        <div className={styles.headerContainer}>
                            <h4 className={styles.annotationCard_subheadings}>Sentiment
                                <div className={styles.tooltipContainer}>
                                    <InfoIcon size={12}/>
                                    <span className={styles.tooltipText}>Sentiments are a communal field (all users control the same field for this topic/subtopic). If a sentiment is already selected, please consider carefully before changing it. </span>
                                </div>
                                :
                            </h4>
                        </div>
                        {
                            (inFL && !isFLParent) ?
                                <p className={styles.annotationCard_sentiment_notavailable}>
                                    Sentiments can only be set for the comment representing the standard of a form
                                    letter.
                                    <br/>
                                    For this form letter, the standard is {flParentID}.
                                </p>
                                :
                                <div className={styles.annotationCard_sentimentwrapper}>
                                    <button onClick={() => updateSentiment(-1)}
                                            style={{backgroundColor: sentiment !== -1 ? 'initial' : '#f57a7a'}}
                                            className={sentiment !== -1 ? styles.unselectedSentiment : styles.selectedSentiment}>
                                        Negative
                                    </button>
                                    <button onClick={() => updateSentiment(0)}
                                            style={{backgroundColor: sentiment !== 0 ? 'initial' : '#f5a97a'}}
                                            className={sentiment !== 0 ? styles.unselectedSentiment : styles.selectedSentiment}>
                                        Mixed
                                    </button>
                                    <button onClick={() => updateSentiment(1)}
                                            style={{backgroundColor: sentiment !== 1 ? 'initial' : '#9dff8c'}}
                                            className={sentiment !== 1 ? styles.unselectedSentiment : styles.selectedSentiment}>
                                        Positive
                                    </button>
                                </div>
                        }

                    </>

            }

            {
                !isTopicSelected ? null :
                    loading ? <Loading/> :
                        <>
                            <div style={{marginTop: 16}} className={styles.headerContainer}>
                                <h4 className={styles.annotationCard_subheadings}>Annotations
                                    <div className={styles.tooltipContainer}>
                                        <InfoIcon size={12}/>
                                        <span className={styles.tooltipText}>Annotations are a communal field (all users store notes in the same box for this topic/subtopic). Below the text field is additional information about the last edit and all editors for this annotation. </span>
                                    </div>
                                    :
                                </h4>
                            </div>
                            <div className={styles.textWrapper}>
                            <textarea className={styles.dynamicUserText} disabled={!isTopicSelected}
                                      style={{resize: "none"}}
                                      value={isTopicSelected ? annotationText : ''}
                                      onChange={e => updateText(e)}/>
                                {
                                    isTopicSelected &&
                                    <p style={{color: isSaved ? "black" : "darkred"}}
                                       className={styles.saved}>{isSaved ? "Annotation Saved" : "Saving..."}</p>
                                }
                            </div>
                        </>
            }
            {
                (!loading && isTopicSelected && annotationUser !== "" && annotationDate !== "")
                    ?
                    <React.Fragment>
                        <p className={styles.lastEdit}><b>Previous Edit: </b>{annotationDate} <b>by</b> {annotationUser}
                        </p>
                        <p className={styles.lastEdit}><b>All Editors: </b> {annotationAuthor}</p>
                        </React.Fragment>
                    :
                    ((!loading && topicFilter !== "") ?
                        <p className={styles.lastEdit}>You're the first one here! Start a new annotation above</p>
                        : null)

            }
            {
                (tracking === "" || tracking === "none" || !isTopicSelected || loading || (Object.keys(topics).length > 0 && topicFilter === "")) ? null :
                    <>
                        <div style={{marginTop: 16}} className={styles.headerContainer}>
                            <h4 className={styles.annotationCard_subheadings}>View Tracking
                                <div className={styles.tooltipContainer}>
                                    <InfoIcon size={12}/>
                                    <span className={styles.tooltipText}>Viewing a topic/subtopic for a comment is unique to your account. For more information on how to sort by this field, open the advanced filters near the top right of this page. </span>
                                </div>
                                :
                            </h4>
                        </div>
                        <div className={styles.markViewedLine}>
                            <h2>{getViewedLabel()}:</h2>

                            <input aria-label={"Form Letter Filter Select"} type={"checkbox"} checked={isTopicViewed}
                                   onChange={markTopicAsViewed}/>
                        </div>
                    </>
            }
        </div>
    )
}

export default InfoCard;