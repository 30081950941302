import React, {useEffect} from 'react';
import styles from './TextDisplay.module.css'
import {getCommentTextAPI, getOriginalAttachmentLinkAPI} from "../../../api/commentAPI";
import {useDispatch, useSelector} from "react-redux";
import Loading from "../loading/loading";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import getHightlightedCommentText from "../../../helper/getHightlightedCommentText";
import PDFElement from "./PDFElement";
import TextElement from "./TextElement";
import getHighlightedIndexes from "../../../helper/getHighlightedIndexes";
import {modalClose, modalOpen} from "../../../store/modalSlice";
import CreateExcerptModal from "./modals/CreateExcerptModal";
import clearSelectedText from "../../../helper/clearSelectedText";
import ManageExcerptModal from "./modals/ManageExcerptModal";

const TextDisplay = ({
                         trackingNum,
                         attachment,
                         documentID,
                         useExcerpt = false,
                         excerpts = {},
                         newExcerptFunc,
                         deleteExcerptFunc,
                         keywordList,
                         isPaused = false
                     }) => {

    const jwt = useSelector((state) => state.user.jwt);
    const docket = useSelector((state) => state.navigation.currentDocket);
    const s3BucketLinks = useSelector((state) => state.dockets.docketLinks);
    const dispatch = useDispatch();

    const [useText, setUseText] = React.useState(true);
    const [text, setText] = React.useState("");
    const [originalResourceLink, setOriginalResourceLink] = React.useState("");
    const [loading, setLoading] = React.useState(false);

    const [potentialExcerpt, setPotentialExcerpt] = React.useState(undefined);
    const [manageExcerptOpen, setManageExcerptOpen] = React.useState(false)

    const topicsHoverRef = React.useRef(null)
    const mouseTrackingRef = React.useRef(false)

    const showHoveredExcerpts = (list) => {
        if (!useExcerpt) {
            return;
        }

        if (topicsHoverRef === null || topicsHoverRef.current === null) {
            return;
        }

        if (topicsHoverRef.current.style.opacity === 0) {
            topicsHoverRef.current.style.opacity = 1
        }

        if (topicsHoverRef.current.dispList === undefined) {
            topicsHoverRef.current.dispList = {}
        }

        if (Object.keys(list).length !== Object.keys(topicsHoverRef.current.dispList).length ||
            (Object.keys(list).length >= 1 && Object.keys(topicsHoverRef.current.dispList).length >= 1 &&
                Object.keys(list))[0] !== Object.keys(topicsHoverRef.current.dispList)[0]
        ) {

            if (Object.keys(list).length === 0) {
                topicsHoverRef.current.innerHTML = '<h3>Excerpts Enabled</h3>'
            } else {
                topicsHoverRef.current.innerHTML = ''
                Object.keys(list).map(key => {
                    topicsHoverRef.current.innerHTML += "<p>" + key + "</p>"

                    list[key].map(subtopic => {
                        topicsHoverRef.current.innerHTML += "<p>- " + subtopic + "</p>"

                        return null;
                    })

                    return null;
                })


            }

            topicsHoverRef.current.dispList = list;
        }
    }

    const closeExcerptModal = () => {
        setPotentialExcerpt(undefined)
        dispatch(modalClose())
        clearSelectedText()
        setManageExcerptOpen(false)
    }

    const saveExcerpts = (e) => {
        if (!useExcerpt) {
            return;
        }

        if (isPaused) {
            return;
        }

        if (potentialExcerpt !== undefined) {
            return;
        }

        if (mouseTrackingRef.current === false) {
            return;
        }

        const selectedText = getHightlightedCommentText(attachment)
        if (selectedText === undefined || selectedText === "") {
            return;
        }

        let indexes = undefined
        if (attachment !== 0) {
            indexes = getHighlightedIndexes(attachment);
        }

        if (selectedText.length < 40) {
            console.warn("New excerpts must be 40 characters or longer.")
            // Determine how to best enforce this. Show a message after X number of tries maybe?
            return;
        }

        if (attachment !== 0 && (indexes === undefined || indexes.length !== 4)) {
            window.alert("There was an error when parsing this attachment excerpt.")
        }

        mouseTrackingRef.current = false

        if (newExcerptFunc !== undefined) {
            setPotentialExcerpt({text: selectedText, indexes: indexes})
            dispatch(modalOpen())
        }
    }

    const uploadExcerpt = (text, index, provisions) => {
        newExcerptFunc(text, index, provisions, closeExcerptModal)
    }

    const renderMethod = () => {

        if (useText) {
            return <TextElement text={text} useExcerpt={useExcerpt} excerpts={excerpts} keywordList={keywordList}
                                attachment={attachment} setHoveredExcerptTopics={showHoveredExcerpts}/>
        }

        const splitID = documentID.toString().split("-")
        const combinedID = `${splitID[0]}-${splitID[1]}-${splitID[2]}_${splitID[3]}_${attachment}`

        return <PDFElement useExcerpt={useExcerpt} excerpts={excerpts} attachment={attachment}
                           setHoveredExcerptTopics={showHoveredExcerpts} keywordList={keywordList}
                           originalFileLink={originalResourceLink}
                           fileURL={`${s3BucketLinks[docket]}${combinedID}.pdf`} fileName={combinedID}/>
    }

    const openManageExcerptPortal = () => {
        dispatch(modalOpen())
        setPotentialExcerpt(undefined)
        setManageExcerptOpen(true)
    }

    useEffect(() => {
        const handleCopy = (e) => {
            // If the selected text is an attachment, this effect sets the clipboard text to the modified text from getHighlightedText.
            if (attachment !== 0 && attachment !== "0") {
                const combinedText = getHightlightedCommentText(attachment);
                if (combinedText.length > 0) {
                    e.clipboardData.setData('text/plain', combinedText);
                    // Prevent the default copy behavior
                    e.preventDefault();
                }
            }
        };

        // Add the 'copy' event listener to the document
        document.addEventListener('copy', handleCopy);

        // Clean up by removing the 'copy' event listener
        return () => {
            document.removeEventListener('copy', handleCopy);
        };
    }, [attachment]);

    React.useEffect(() => {
        if (attachment === 0 || attachment === "0") {
            setLoading(true)
            getCommentTextAPI(jwt, docket, trackingNum, attachment).then(
                r => {
                    setUseText(true);
                    setText(r);
                    setLoading(false);
                }
            )
        } else {
            setUseText(false)
            setText("Attachment/PDF rendering is enabled.")
            setOriginalResourceLink("")

            getOriginalAttachmentLinkAPI(jwt, docket, trackingNum, attachment).then(r => {
                if (r.success && r.data !== '') {
                    setOriginalResourceLink(r.data)
                }
            })
        }

        closeExcerptModal()
    }, [trackingNum, attachment])

    if (topicsHoverRef !== null && topicsHoverRef.current !== null) {
        if (topicsHoverRef.current.style.opacity < 1 && useExcerpt) {
            topicsHoverRef.current.style.opacity = 1
        }

        if (topicsHoverRef.current.style.opacity > 0 && !useExcerpt) {
            topicsHoverRef.current.style.opacity = 0
        }
    }

    return (
        <div className={styles.wrapper}
             onMouseDown={() => {
                 mouseTrackingRef.current = true
             }}
             onMouseUp={saveExcerpts}>
            <div className={styles.content}>
                {
                    loading ? <Loading/> : renderMethod()
                }
            </div>
            <div ref={topicsHoverRef} className={styles.excerpt_topic_hover} style={{opacity: 0}}>
                <h3>Excerpts Enabled</h3>
            </div>
            {
                useExcerpt &&
                <button onClick={openManageExcerptPortal} className={styles.excerpt_topic_open_manager}>
                    Manage Excerpts
                </button>
            }


            <ManageExcerptModal isOpen={manageExcerptOpen} closeModal={closeExcerptModal}
                                data={excerpts} onMouseOver={() => mouseTrackingRef.current = false}
                                deleteFunc={deleteExcerptFunc}
                                addFunc={(text, idx, topics, att) => newExcerptFunc(text, idx, topics, () => {
                                }, att)}
            />

            <CreateExcerptModal onMouseOver={() => mouseTrackingRef.current = false}
                                data={potentialExcerpt} closeModal={closeExcerptModal}
                                isOpen={potentialExcerpt !== undefined && potentialExcerpt !== null}
                                submitFunc={uploadExcerpt}/>
        </div>
    );
};

export default TextDisplay;
